<template>
    <div v-if="showModal" :class="['main-category-select-modal modal',showModal?'modal-open':'']" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">يرجى اختيار فئة</h3>
                <p class="modal-content__info">حتى تتمكن من العثور على العروض المناسبة تماماً.</p>

                <div class="main-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[0])">
                                <div class="card bg-jade">
                                    <div class="card__img">
                                        <img src="/assets/icons/beruf.png" alt="التدريب والتعليم الإضافي">
                                    </div>
                                    <div class="card__content">
                                        <h3>التدريب والتعليم الإضافي</h3>
                                        <p>ابحث عن عروض التدريب المهني والتعليم الإضافي.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[1])">
                                <div class="card bg-meganta">
                                    <div class="card__img">
                                        <img src="/assets/icons/hilfe.png" alt="المشورة والمساعدة">
                                    </div>
                                    <div class="card__content">
                                        <h3>المشورة والمساعدة</h3>
                                        <p>ابحث عن الدعم في جميع المواقف.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[2])">
                                <div class="card bg-blue">
                                    <div class="card__img">
                                        <img src="/assets/icons/freizeit.png" alt="أوقات الفراغ">
                                    </div>
                                    <div class="card__content">
                                        <h3>أوقات الفراغ</h3>
                                        <p>اعثر على أفكار لأنشطتك الترفيهية.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>