<template>
    <div v-if="showModal" :class="['main-category-select-modal modal',showModal?'modal-open':'']" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">Bitte wähle eine Kategorie</h3>
                <p class="modal-content__info">So findest du genau passende Angebote.</p>

                <div class="main-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[0])">
                                <div class="card bg-jade">
                                    <div class="card__img">
                                        <img src="/assets/icons/beruf.png" alt="Ausbildung &amp; Weiterbildung">
                                    </div>
                                    <div class="card__content">
                                        <h3>Ausbildung &amp; Weiterbildung</h3>
                                        <p>Finde professionelle Ausbildungs- &amp; Weiterbildungsangebote.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[1])">
                                <div class="card bg-meganta">
                                    <div class="card__img">
                                        <img src="/assets/icons/hilfe.png" alt="Beratung &amp; Hilfe">
                                    </div>
                                    <div class="card__content">
                                        <h3>Beratung &amp; Hilfe</h3>
                                        <p>Finde Unterstützung in allen Lebenslagen.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[2])">
                                <div class="card bg-blue">
                                    <div class="card__img">
                                        <img src="/assets/icons/freizeit.png" alt="أوقات الفراغ">
                                    </div>
                                    <div class="card__content">
                                        <h3>أوقات الفراغ</h3>
                                        <p>Finde Ideen für deine Freizeitgestaltung.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>